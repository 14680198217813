import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { FormGroup, FormControl, FormLabel, ToggleButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import parseISO from 'date-fns/parseISO'
import "react-datepicker/dist/react-datepicker.css";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import "./Items.css";

export default function Items() {
  const { id } = useParams();
  const history = useHistory();
  const [item, setItem] = useState(null);
  const [content, setContent] = useState("");
  const [quantity, setQuantity] = useState("");
  const [lowstock, setLowStock] = useState("");
  const [staple, setStaple] = useState(false);
  // const [content2, setContent2] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    
    function loadItem() {
      return API.get("items", `/items/${id}`);
    }

    async function onLoad() {
      try {
        const item = await loadItem();
        const { content, expires, quantity, lowstock, staple } = item;

        setContent(content);
        setQuantity(quantity);
        setLowStock(lowstock);
        setStaple(staple);

        setStartDate(parseISO(expires));
        
        setItem(item);

      }
      catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);
  

  function validateForm() {
    return content.length > 0;
  }

  function saveItem(content, expires, quantity, lowstock, staple) {
    return API.put("items", `/items/${id}`, {
      body: { content, expires, quantity, lowstock, staple }
    });
  }

  async function handleSubmit(event) {

    event.preventDefault();

    //setIsLoading(true);
    
    const expires = startDate;

    try {

      await saveItem({
        content,
        expires,
        quantity,
        lowstock,
        staple
      });
      history.push("/");
    }
    catch (e) {
      onError(e);
      //setIsLoading(false);
    }
  }

  function deleteItem() {
    return API.del("items", `/items/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "If you're planning on having this item again but it's just out of stock for now, you should just set the quantity to zero. \n\n Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteItem();
      history.push("/");
    }
    catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  function ToggleExpires() {
    if (!staple) {
      return (
        <div>
          <FormGroup controlId="content">
            <FormLabel>Expiration date</FormLabel>
            <br />
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </FormGroup>

          <FormGroup controlId="content">
            <FormLabel>Quantity</FormLabel>
            <br />
            <FormControl
              value={quantity}
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="content">
            <FormLabel>Low Stock Alert (Optional)</FormLabel>
            <br />
            <FormControl
              value={lowstock}
              type="number"
              onChange={(e) => setLowStock(e.target.value)}
            />
          </FormGroup>
        </div>
      );
    } else {
      return null
    }
  }

  return (
    <div className="Items">
      {item && (
        <form onSubmit={handleSubmit}>
        <FormGroup controlId="content">
          <FormLabel>Item name</FormLabel>
            <FormControl
              value={content}
              type="text"
              onChange={e => setContent(e.target.value)}
            />
          </FormGroup>
          
          <ToggleExpires/>

          Staple items are used too frequently to track inventory. Check this box to keep this item in your shopping list and stop tracking expiration.<br></br>
          <ToggleButton
            className="mb-2"
            id="toggle-staple"
            type="checkbox"
            variant="outline-primary"
            checked={staple}
            value="1"
            onChange={(e) => setStaple(e.target.checked)}
          >
             Staple
          </ToggleButton>

          <LoaderButton
          block
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Save
        </LoaderButton>

          <LoaderButton
            block
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </form>
      )}
    </div>
  );
}
