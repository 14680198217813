import React, { useState, useEffect } from "react";
import {
  ListGroup,
  ListGroupItem,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./OutOfStock.css";

export default function OutOfStock() {
  const [items, setitems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const items = await loaditems();
        setitems(items);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loaditems() {
    console.log("loaded out of stock items");
    return API.get("items", "/items");
  }

  function renderitemsList(items) {
    console.log(items);
    return [{}].concat(items).map((item, i) =>
      i !== 0 ? (
        (item.quantity < 1 || item.staple) && (
          <LinkContainer key={item.itemId} to={`/items/${item.itemId}`}>
            <ListGroupItem>
              <b>{item.content.trim().split("\n")[0]}</b>
              <br></br>
            </ListGroupItem>
          </LinkContainer>
        )
      ) : (
        <LinkContainer
          key="new"
          to="/items/new"
          style={{ background: "#98FB98" }}
        >
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Add a new item
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Inexpire</h1>
        <p>Stop throwing away food!</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
        <Container>
          <Row className="show-Container">
            <Col xs={4} md={6}>
              <Card.Heading>
                <Card.Title componentClass="h3">Don't waste food.</Card.Title>
              </Card.Heading>
              <Card.Body>
                Inexpire allows you to track the expiration date of food,
                drinks, medicine, or anything else that expires.
              </Card.Body>
            </Col>
            <Col xs={4} md={6}>
              <Card.Heading>
                <Card.Title componentClass="h3">Don't waste money.</Card.Title>
              </Card.Heading>
              <Card.Body>
                Inexpire prioritizes showing the items with the closest
                expiration date. Don't throw it out just yet!
              </Card.Body>
            </Col>
          </Row>
          <Row className="show-Container">
            <Col xs={4} md={6}>
              <Card.Heading>
                <Card.Title componentClass="h3">Leftovers again?!</Card.Title>
              </Card.Heading>
              <Card.Body>
                Quickly and easily set dates for leftovers! Just select "three
                days" or "five days" (coming soon)
              </Card.Body>
            </Col>
            <Col xs={4} md={6}>
              <Card.Heading>
                <Card.Title componentClass="h3">
                  When did we open that...?
                </Card.Title>
              </Card.Heading>
              <Card.Body>
                Track your items as soon as they're opened and never find
                another "science experiment" in the back of your fridge.!
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  function renderitems() {
    return (
      <div className="items">
        <b>Your shopping list</b>
        <br></br>
        <ListGroup>{!isLoading && renderitemsList(items)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="OutOfStock">
      {isAuthenticated ? renderitems() : renderLander()}
    </div>
  );
}
