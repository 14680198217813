import React from "react";
// import { API } from "aws-amplify";
// import { useHistory } from "react-router-dom";
// import { onError } from "../lib/errorLib";
// import config from "../config";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import BillingForm from "../components/BillingForm";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";

export default function Settings() {
  // const history = useHistory();
  // const [isLoading, setIsLoading] = useState(false);
  // const stripePromise = loadStripe(config.STRIPE_KEY);

  // function billUser(details) {
  //   return API.post("items", "/billing", {
  //     body: details
  //   });
  // }

  // async function handleFormSubmit(storage, { token, error }) {
  //   if (error) {
  //     onError(error);
  //     return;
  //   }
  
  //   setIsLoading(true);
  
  //   try {
  //     await billUser({
  //       storage,
  //       source: token.id,
  //     });
  
  //     alert("Your card has been charged successfully!");
  //     history.push("/");
  //   } catch (e) {
  //     onError(e);
  //     setIsLoading(false);
  //   }
  // }
  
  return (
    <div className="Settings">
      <LinkContainer to="/settings/email">
        <LoaderButton block bsSize="large">
          Change Email
        </LoaderButton>
      </LinkContainer>
      <LinkContainer to="/settings/password">
        <LoaderButton block bsSize="large">
          Change Password
        </LoaderButton>
      </LinkContainer>
      {/* <hr />
      <Elements
        stripe={stripePromise}
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
          },
        ]}
      >
        <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
      </Elements> */}
    </div>
  );
}