import React, { useState } from "react";
import {
  FormGroup,
  FormControl,
  FormLabel,
  ToggleButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import "./NewItem.css";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function NewItem(props) {
  const [content, setContent] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [lowstock, setLowStock] = useState(2);
  //const [content2, setContent2] = useState("");
  const [staple, setStaple] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());

  function validateForm() {
    return content.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const expires = startDate;

    try {
      await createItem({ content, expires, quantity, lowstock, staple });
      history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createItem(content, expires, quantity, lowstock, staple) {
    console.log("made it to createItem");
    console.log(content, expires, quantity, lowstock, staple);

    if (!typeof quantity === "number") {
      console.log("quantity is not a number");
      quantity = 1;
    }

    if (!typeof lowstock === "number") {
      console.log("lowstock is not a number");
      lowstock = 1;
    }

    return API.post("items", "/items", {
      body: { content, expires, quantity, lowstock, staple },
    });
  }

  function ToggleExpires() {
    if (!staple) {
      return (
        <div>
          <FormGroup controlId="content">
            <FormLabel>Expiration date</FormLabel>
            <br />
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </FormGroup>

          <FormGroup controlId="content">
            <FormLabel>Quantity</FormLabel>
            <br />
            <FormControl
              value={quantity}
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="content">
            <FormLabel>Low Stock Alert (Optional)</FormLabel>
            <br />
            <FormControl
              value={lowstock}
              type="number"
              onChange={(e) => setLowStock(e.target.value)}
            />
          </FormGroup>
        </div>
      );
    } else {
      return null
    }
  }

  return (
    <div className="NewItem">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="content">
          <FormLabel>Item name</FormLabel>
          <FormControl
            value={content}
            componentClass="textarea"
            onChange={(e) => setContent(e.target.value)}
          />
        </FormGroup>

        <ToggleExpires/>

        <div>
          Staple items are used too frequently to track inventory. Check this
          box to keep this item in your shopping list.<br></br>
          <ToggleButton
            className="mb-2"
            id="toggle-staple"
            type="checkbox"
            variant="outline-primary"
            checked={staple}
            value="1"
            onChange={(e) => setStaple(e.target.checked)}
          >
            Staple
          </ToggleButton>
          <br />
        </div>

        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
    </div>
  );
}
