import React, { useState, useEffect } from "react";
import {
  ListGroup,
  ListGroupItem,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./Home.css";

export default function Home() {
  const [items, setitems] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const items = await loaditems();
        setitems(items);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loaditems() {
    return API.get("items", "/items");
  }

  function formatItemName(item) {
    let itemQuantity = Number(item.quantity);
    let theItem = "unknown";
    if (typeof item.content === "string" || item.content instanceof String) {
      theItem = item.content.trim().split("\n")[0];
    } else {
      theItem = "unknown";
    }
    let itemContent = theItem;
    let lowstock = 2;

    // check to see if it's low in stock
    if (item.lowstock) {
      lowstock = item.lowstock;
    } else {
      lowstock = 2;
    }

    if (itemQuantity < lowstock) {
      itemContent = theItem + " (LOW STOCK)";
    } else {
      itemContent = theItem;
    }

    // Check to see if it expires soon, display a message
    const week = new Date();
    week.setDate(week.getDate() + 7);

    const current = new Date();
    current.setDate(current.getDate());

    if (Date.parse(item.expires) < current) {
      itemContent = itemContent + " ++EXPIRED!++";
    } else if (Date.parse(item.expires) < week) {
      itemContent = itemContent + " --EXPIRING SOON!--";
    }

    let itemName = itemContent.toString();
    return itemName;
  }

  function formatListElement(item) {
    // Default to prevent errors
    let lowstock = 2;

    // Check the DB to see if there is a low stock amount
    // if not, resort to the default of 2
    if (item.lowstock) {
      lowstock = item.lowstock;
    }

    // If item is a staple, don't display in stock at all

    if (item.staple) {
      return 
    }

    if (Number(item.quantity) > Number(lowstock)) {
      // there is more in stock than required, everything is okay
      return (
        <LinkContainer
          key={item.itemId}
          to={`/items/${item.itemId}`}
          style={{ background: "#E7DFDD" }}
        >
          <ListGroupItem>
            <b>{formatItemName(item)}</b>
            <br></br>
            {"Quantity: " + item.quantity}
            <br></br>
            {"Expires: " +
              new Date(item.expires).toLocaleString().split(",")[0]}
          </ListGroupItem>
        </LinkContainer>
      );
    } else {
      // there is less in stock than required, display red

      return (
        <LinkContainer
          key={item.itemId}
          to={`/items/${item.itemId}`}
          style={{ background: "#FFCCCC" }}
        >
          <ListGroupItem>
            <b>{formatItemName(item)}</b>
            <br></br>
            {"Quantity: " + item.quantity}
            <br></br>
            {"Expires: " +
              new Date(item.expires).toLocaleString().split(",")[0]}
          </ListGroupItem>
        </LinkContainer>
      );
    }
  }

  function renderitemsList(items) {
    return [{}]
      .concat(items)
      .sort(
        (a, b) => new Date(a.expires).getTime() - new Date(b.expires).getTime()
      )
      .map((item, i) =>
        i !== 0 ? (
          item.quantity > 0 && formatListElement(item)
        ) : (
          <>
            <LinkContainer
              key="new"
              to="/items/new"
              style={{ background: "#98FB98" }}
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Add a new item
                </h4>
              </ListGroupItem>
            </LinkContainer>
            <LinkContainer
              key="oos"
              to="/shopping"
              style={{ background: "#FFFFE0" }}
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> View shopping list
                </h4>
              </ListGroupItem>
            </LinkContainer>
            <br></br>
          </>
        )
      );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Inexpire</h1>
        <p>Stop throwing away food!</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
        <Container>
        <Row className="show-Container">
          <Col xs={4} md={6}>
            <Card.Body>
              <Card.Title>What's in the pantry?</Card.Title>
            </Card.Body>
            <Card.Text>Inexpire allows you to track the expiration date of food, drinks, medicine, or anything else that expires.</Card.Text>
          </Col>
          <Col xs={4} md={6}>
            <Card.Body>
             <Card.Title>Grocery shopping is a breeze!</Card.Title>
            </Card.Body>
            <Card.Text>Shop with confidence knowing what you already have at home. Inexpire provides customized shopping lists based on what you've used already</Card.Text>
          </Col>
        </Row>
         <Row className="show-Container">
          <Col xs={4} md={6}>
            <Card.Body>
              <Card.Title>When did we open that...?</Card.Title>
            </Card.Body>
            <Card.Text>Leftovers aren't a mystery anymore. Inexpire tracks expiration dates and shows if that chicken is still good</Card.Text>
          </Col>
          <Col xs={4} md={6}>
            <Card.Body>
             <Card.Title>Out of stock alerts</Card.Title>
            </Card.Body>
            <Card.Text>Inexpire will let you know when you're running low on an item so you can stock up before you run out</Card.Text>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }

  function renderitems() {
    return (
      <div className="items">
        <h1>Your items</h1>
        <ListGroup>{!isLoading && renderitemsList(items)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderitems() : renderLander()}
    </div>
  );
}
